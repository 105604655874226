<template>
  <portal
    to="modal"
    :disabled="!isShow"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      :is-open="isShow"
      class="ask-ticketek-access-token-modal"
      header="Connect To Ticketek"
      width="590px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="handleCloseClick"
    >
      <div
        slot="body"
        :class="[
          'ask-ticketek-access-token-modal-body',
          $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
        ]"
      >
        <div
          class="u-display-flex u-margin-top-8 u-margin-bottom-3"
        >
          <ar-text
            size="xs"
            text="Subscribe associated contacts to list"
            weight="bold"
            :style="{
              color: $arStyle.color.blueGrey800,
            }"
          />
          <ar-icon
            v-tooltip.top="{
              content: `Automatically subscribe all opted-in attendees of events linked to this Ticketek bucket into a messaging list. Opted-in attendees will be capable of receiving emails.`,
            }"
            name="tooltip-question-mark"
            class="u-margin-left-2"
            height="15px"
            width="15px"
            :color="$arStyle.color.purple500"
          />
        </div>
        <ar-field
          :error-messages="[
            veeErrors.first('messageList')
          ]"
          :style="{
            marginTop: '15px',
          }"
        >
          <am2-message-list-select
            message-list-type="manual"
            :show-stats="false"
            v-model="messageList"
            :channels="['email']"
          />
        </ar-field>
        <ar-field
          label="S3 Bucket Name"
          :style="{
            marginTop: '20px',
          }"
          :error-messages="[
            veeErrors.first('s3BucketName')
          ]"
        >
          <ar-input
            data-test-id="ticketek-modal-s3-bucket-name"
            v-model="s3BucketName"
            v-validate="'required'"
            data-vv-name="s3BucketName"
            data-vv-as="S3 Bucket Name"
            autocomplete="ticketek-s3-bucket-name"
            placeholder="Please enter s3 bucket name"
          />
        </ar-field>
        <ar-field
          label="S3 Bucket Region"
          :style="{
            marginTop: '20px',
          }"
          :error-messages="[
            veeErrors.first('s3BucketRegion')
          ]"
        >
          <ar-input
            data-test-id="ticketek-modal-s3-bucket-region"
            v-model="s3BucketRegion"
            v-validate="'required|isValidRegion'"
            data-vv-name="s3BucketRegion"
            data-vv-as="S3 Bucket Region"
            autocomplete="ticketek-s3-bucket-region"
            placeholder="Please enter s3 bucket region"
          />
        </ar-field>
        <ar-field
          label="S3 Bucket Access Key"
          :style="{
            marginTop: '20px',
          }"
          :error-messages="[
            veeErrors.first('s3BucketAccessKey')
          ]"
        >
          <ar-input
            data-test-id="ticketek-modal-s3-bucket-access-key"
            v-model="s3BucketAccessKey"
            v-validate="'required'"
            data-vv-name="s3BucketAccessKey"
            data-vv-as="S3 Bucket Access Key"
            autocomplete="ticketek-s3-bucket-acess-key"
            placeholder="Please enter s3 bucket access key"
          />
        </ar-field>
        <ar-field
          label="S3 Bucket Secret Key"
          :style="{
            marginTop: '20px',
          }"
          :error-messages="[
            veeErrors.first('s3BucketSecretKey')
          ]"
        >
          <ar-input
            data-test-id="ticketek-modal-s3-bucket-secret-key"
            v-model="s3BucketSecretKey"
            v-validate="'required'"
            data-vv-name="s3BucketSecretKey"
            data-vv-as="S3 Bucket Secret Key"
            autocomplete="ticketek-s3-bucket-secret-key"
            type="password"
            placeholder="Please enter s3 bucket secret key"
          />
        </ar-field>
        <ar-link-button
          data-test-id="ticketek-modal-setup-support-link"
          class="u-margin-top-6"
          text="Get your S3 credentails on Ticketek"
          :has-underline="true"
          @click="handleSetupClick"
        />

        <ar-field
          :style="{
            marginTop: '15px',
          }"
         >
           <ar-checkbox
             label="Assign tags from the &ldquo;product-marketing-type&rdquo; field<br>to newly created fan accounts."
             key="tag-fans"
             class="u-margin-right-5"
             data-test-id="checkbox-ticketek-assign-tags"
             v-model="tagFans"
           />
        </ar-field>

      </div>
      <div
        slot="footer"
        :class="[
          'ask-ticketek-access-token-modal-footer',
          $arMediaQuery.window.maxWidth('xs') && 'u-padding-x-3',
        ]">
        <ar-simple-button
          data-test-id="ticketek-modal-confirm"
          text="Confirm"
          :loading="loading"
          :disabled="confirmButtonDisabled"
          :style="{ width: '108px' }"
          @click="handleConfirmClick"
        />
      </div>
    </ar-modal>
  </portal>
</template>

<script>

import {lookup} from 'aws-regions'

export default {
  name: 'AskTicketS3CredentialsModal',
  components: {},
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      s3BucketName: null,
      s3BucketRegion: null,
      s3BucketAccessKey: null,
      s3BucketSecretKey: null,
      tagFans: false,
      messageList: null,
      setupUrl: "https://developer-acct.ticketmaster.com/",
    };
  },

  async created() {
    this.initializeValidator();
  },

  computed: {
    confirmButtonDisabled() {
      return !this.s3BucketName || !this.s3BucketRegion || !this.s3BucketAccessKey || !this.s3BucketSecretKey;
    },
  },

  watch: {
    isShow(val) {
      if (val) {
        this.s3BucketName = null;
        this.s3BucketRegion = null;
        this.s3BucketAccessKey = null;
        this.s3BucketSecretKey = null;
        this.tagFans = false;
        this.messageList = null;
      }
    },
  },

  methods: {
    async validateInputs() {
      return await this.$validator.validateAll();
    },
    async handleConfirmClick() {
      const valid = await this.validateInputs();
      if (!valid) {
        return;
      }
      this.$emit('confirm', this.s3BucketName, this.s3BucketRegion, this.s3BucketAccessKey, this.s3BucketSecretKey, this.messageList, this.tagFans);
    },
    handleCloseClick() {
      this.$emit('cancel');
    },
    handleSetupClick() {
      window.open(this.setupUrl, '_blank');
    },
    initializeValidator() {
      this.$validator.extend('isValidRegion', {
        getMessage: field => `Please input a valid AWS S3 Bucket Region`,
        validate: value => {
          return !!lookup({ code: value })
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.ask-ticketek-access-token-modal {
  .ask-ticketek-access-token-modal-body {
    padding: 20px 30px 30px;
  }
  .ask-ticketek-access-token-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 25px 30px;
    height: 100px;
  }
}
</style>

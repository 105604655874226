<template>
  <section
    :class="[
      'wrapper',
      'content',
      'content--centered',
      $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max',
      $arMediaQuery.pageContent.maxWidth('xs') && 'xs-max',
    ]">
    <am2-ask-ticketek-s3-credentials-modal
      :is-show="showAskTicketekS3CredentialsModal"
      :loading="isCreatingTicketekIntegration"
      @confirm="handleTicketekS3CredentialsConfirm"
      @cancel="showAskTicketekS3CredentialsModal = false"
    />
    <am2-opt-in-settings-modal
      :is-show="showOptinSettingsModal"
      @cancel="showOptinSettingsModal = false"
      @TicketekTagFansChange="handleTicketekTagFansChange"
      providerName="Ticketek"
      :message-list-oid="currentMessageListOid"
      :is-ticketek-tag-fans="tagFans"
    />
    <am2-step-link
      class="step-back-link"
      text="Back to integrations"
      @click="handleBackLinkClick" />
    <div
      :class="[
        'title-section',
        $arMediaQuery.pageContent.maxWidth('md') && 'md-max',
      ]">
      <div class="title-section-left">
        <am2-icon-title-section
          title="Ticketek"
          description="Automatically sync your members from Ticketek"
          :icon-props="{
            name: 'ticketek',
            color: $arStyle.color.ticketek,
            height: '46px',
            width: '40px',
          }"
        />
      </div>
      <div class="title-section-right" v-if="!isFetchingTicketekIntegration && integrationsSummary">
        <am2-new-integration-account-select
          v-if="!isFetchingTicketekIntegration && ticketekIntegration && currentIntegration"
          :integration-oid="currentIntegration.oid"
          :items="integrationsSummary"
          @select="handleIntegrationChange"
          @new-account="handleAddNewIntegration"
          :style="{ marginRight: '10px', maxWidth: 'calc(100vw - 80px)', }"
        />
        <am2-expand-button-dropdown
          align="left"
          :button-props="{ iconName: 'settings' }"
          :items="[
            {
              name: 'Open Ticketek',
              value: 'goToTicketekSite',
            },
            {
              name: 'Ticketek Settings',
              value: 'openSettingsModal',
            },
            {
              name: 'Remove Account',
              value: 'removeIntegration',
            },
          ]"
          @select="handleSettingOptionSelect" />
      </div>
    </div>


    <section v-if="isAddingTicketekIntegration || isFetchingTicketekIntegration" class="integration-loading-container">
      <am2-loading-bubble />
    </section>
    <section v-else>
      <SyncBlockContainer
        class="sync-block-container"
        :is-fetching-sync-task="isFetchingSyncTask"
        :sync-status="syncStatus"
        :sync-time="syncTime"
        :sync-copies="{
          'in-progress': 'Syncing data from Ticketek',
          'pending': 'The data sync is still pending',
          'completed': 'Your Ticketek account is currently connected',
          'cancelled': 'Your Ticketek account connection is cancelled',
          'failed': syncErrorMessage,
          'default': 'Syncing tasks not found',
        }"
        @startSync="handleStartSync"
        @stopSync="handleStopSync"
      />
    </section>

  </section>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import moment from 'moment';
import SyncBlockContainer from '../components/sync-block-container';

export default {
  name: 'TicketekIntegration',
  layout: 'default',
  components: {
    SyncBlockContainer,
  },

  data: () => ({
    showAskTicketekS3CredentialsModal: false,
    showOptinSettingsModal: false,
    currentlySelectedIntegrationIndex: 0,
    syncStatusPoller: null,
    currentMessageListOid: null,
    tagFans: false,
  }),

  computed: {
    ...mapState({
      ticketekIntegration: state => state.ticketekIntegration.integration,
      isFetchingTicketekIntegration: state => state.ticketekIntegration.isFetchingIntegration,
      isAddingTicketekIntegration: state => state.ticketekIntegration.isAddingIntegration,
      isCreatingTicketekIntegration: state => state.ticketekIntegration.isCreatingIntegration,
      isFetchingSyncTask: state => state.ticketekIntegration.isFetchingSyncTask,
      syncTask: state => state.ticketekIntegration.syncTask,
      syncStatus: state => state.ticketekIntegration.syncStatus,
    }),

    ticketekIntegrationEnabled() {
      return process.env.arEnableTicketekIntegration;
    },

    syncErrorMessage() {
      return this.syncTask?.statusDetails?.lastError || this.syncTask?.statusDetails?.reason?.userError;
    },

    syncTime() {
      return this.syncTask?.sysMtime || null;
    },

    integrationsSummary() {
      if (!this.ticketekIntegration) return null;
      const summary = this.ticketekIntegration
        .filter(i => i.status !== 'failed')
        .map(i => ({
          oid: i.oid,
          initials: 'S 3',
          name: i.meta?.bucketName,
          messageListOid: i.meta?.messageListOid,
          tagFans: i.meta?.tagFans,
        }));

      if (this.currentlySelectedIntegrationIndex != null) {
        this.currentMessageListOid = summary[this.currentlySelectedIntegrationIndex].messageListOid;
        this.tagFans = summary[this.currentlySelectedIntegrationIndex].tagFans;
      }
      return summary
    },

    currentIntegration() {
      if (this.currentlySelectedIntegrationIndex === null) return null;
      if (!this.ticketekIntegration || this.ticketekIntegration.length < 1 || !this.ticketekIntegration[this.currentlySelectedIntegrationIndex]) return null;
      return this.ticketekIntegration[this.currentlySelectedIntegrationIndex];
    },
  },

  async mounted() {
    if (!this.ticketekIntegrationEnabled) {
      this.handleBackLinkClick();
    }
    await this.fetchIntegration();
    this.startFetchTasksPolling();
  },

  beforeDestroy() {
    this['ticketekIntegration/RESET_INTEGRATION']();

    if (this.syncStatusPoller) {
      clearInterval(this.syncStatusPoller);
    }
  },

  methods: {
    ...mapActions([
      'SHOW_CONFIRM',
      'ticketekIntegration/CREATE_TICKETEK_INTEGRATION',
      'ticketekIntegration/UPDATE_TICKETEK_INTEGRATION',
      'ticketekIntegration/FETCH_INTEGRATION',
      'ticketekIntegration/DELETE_INTEGRATION',
      'ticketekIntegration/SYNC_START',
      'ticketekIntegration/SYNC_STOP',
      'ticketekIntegration/FETCH_SYNC_TASK',
      'promoterTasks/START_POLLING_PENDING_TASKS',
    ]),
    ...mapMutations([
      'ticketekIntegration/RESET_INTEGRATION',
    ]),
    async fetchIntegration() {
      await this['ticketekIntegration/FETCH_INTEGRATION']();
      if (!this.ticketekIntegration || this.ticketekIntegration.length === 0) {
        this.$router.push({
          path: '/settings/integrations',
        });
      }
    },

    async fetchTasks() {
      await this['ticketekIntegration/FETCH_SYNC_TASK'](this.currentIntegration.oid);

      if (this.currentIntegration && this.hasExistingTask && this.syncStatus !== "in-progress") {
        window.clearInterval(this.syncStatusPoller);
        this.syncStatusPoller = null;
        this['promoterTasks/START_POLLING_PENDING_TASKS']({});
      }
    },

    handleTicketekTagFansChange(value) {
      let integration = this.ticketekIntegration[this.currentlySelectedIntegrationIndex];
      let oid = integration.oid;
      let meta = integration.meta;
      meta.tagFans = value;
      this['ticketekIntegration/UPDATE_TICKETEK_INTEGRATION']({oid, meta});
    },

    startFetchTasksPolling() {
      if (this.currentIntegration?.oid && !this.isFetchingSyncTask) {
        this.fetchTasks();
        this.syncStatusPoller = setInterval(this.fetchTasks, 3000);
      }
    },

    async handleStartSync() {
      setTimeout(() => {
        this['promoterTasks/START_POLLING_PENDING_TASKS']({});
      }, 750);
      await this['ticketekIntegration/SYNC_START']({ oid: this.currentIntegration.oid });
      if (this.syncStatus !== 'completed') {
        this.syncStatusPoller = setInterval(this.fetchTasks, 3000);
      }
    },

    hasExistingTask() {
      return this.syncTask !== null;
    },

    handleBackLinkClick() {
      this.$router.push({ path: '/settings/integrations' });
    },

    handleIntegrationChange(item) {
      this.currentlySelectedIntegrationIndex =
        this.ticketekIntegration.findIndex(integration => integration.oid === item.oid);

      this.updateCurrentMessageListOid();
      this.fetchTasks();
    },

    updateCurrentMessageListOid() {
      if (this.currentlySelectedIntegrationIndex === null || !this.integrationsSummary) {
        return null;
      }

      this.currentMessageListOid = this.integrationsSummary[this.currentlySelectedIntegrationIndex].messageListOid;
      this.tagFans = this.integrationsSummary[this.currentlySelectedIntegrationIndex].tagFans;
    },

    async handleTicketekS3CredentialsConfirm(s3BucketName, s3BucketRegion, s3BucketAccessKey, s3BucketSecretKey, messageList, tagFans) {
      const succeed = await this['ticketekIntegration/CREATE_TICKETEK_INTEGRATION']({
        s3BucketName,
        s3BucketRegion,
        s3BucketAccessKey,
        s3BucketSecretKey,
        messageList,
        tagFans,
      });
      if (succeed) {
        await this.fetchIntegration();
        this.currentlySelectedIntegrationIndex = this.ticketekIntegration.length - 1;
        this.showAskTicketekS3CredentialsModal = false;
        this.startFetchTasksPolling();
      } else {
        this.currentlySelectedIntegrationIndex = 0;
      }
    },

    async handleAddNewIntegration() {
      this.showAskTicketekS3CredentialsModal = true;
    },

    handleStopSync() {
      this['ticketekIntegration/SYNC_STOP']({oid: this.currentIntegration.oid});
      setTimeout(() => {
        this['promoterTasks/START_POLLING_PENDING_TASKS']({});
      }, 750);
    },

    async handleSettingOptionSelect(item) {
      if (item.value === 'removeIntegration') {
        const answer = await this.SHOW_CONFIRM({
          messageHtml: 'Removing the Ticketek integration will stop syncing your orders and customer data from Ticketek.',
          confirmButtonText: 'Remove integration',
          iconName: 'alert-question-mark',
          title: 'Are you sure?',
        });
        if (answer) {
          try {
            await this['ticketekIntegration/DELETE_INTEGRATION']({oid: this.currentIntegration.oid});
            if (this.ticketekIntegration.length >= 1) {
              this.currentlySelectedIntegrationIndex = 0;
              this.fetchTasks();
            } else {
              this.$router.push({ path: '/settings/integrations' });
            }
          } catch (e) {
            this.$arNotification.push({ type: 'error', message: 'Failed to remove integration' });
          }
        }
      } else if (item.value === 'goToTicketekSite') {
        if (!this.currentIntegration) return;
        var win = window.open('https://developer-acct.ticketmaster.com/user/login?destination=user', '_blank');
        win.focus();
      } else if (item.value == 'openSettingsModal') {
        if (!this.currentIntegration) return;

        this.showOptinSettingsModal = true;
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.wrapper {

  &.sm-max {
    padding: 0 24px;
  }
  &.xs-max {
    padding: 0 12px;
  }


  .step-back-link {
    margin-top: 48px;
  }

  .title-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 48px;

    .title-section-left {
      display: inline-flex;
      align-items: flex-start;
    }

    .title-section-right {
      display: inline-flex;
      align-items: center;

      .select {
        width: 300px;
        margin-right: 10px;
      }
    }

    &.md-max {
      flex-direction: column;
      align-items: flex-start;

      .title-section-right {
        margin-top:16px;
      }
    }
  }

  .integration-loading-container {
    display: flex;
    justify-content: center;
    margin-top: 65px;
  }


  .sync-block-container {
    background-color: #FFF;
    margin-top:64px;
  }
}
</style>
